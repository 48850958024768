<template>
<div class="app-container">
  <el-row v-loading="loading" element-loading-text="Cargando..." :gutter="10" type="flex" justify="center">
    <el-col :span="12">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <strong><span>Crear Usuario</span></strong>
        </div>
        <div class="text item">
          <ValidationObserver ref="form" v-slot="{ invalid }">
              <el-form label-position="top" label-width="120px" :model="form">
                <el-form-item label="Foto de Perfil">
                    <el-upload
                      class="avatar-uploader"
                      drag
                      name="image"
                      action=""
                      :show-file-list="false"
                      :http-request="postImage"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      >
                      <img v-if="imageUrl" :src="imageUrl" class="avatar">
                      <i v-else class="el-icon-upload"></i>
                      <div class="el-upload__text">Suelta tu foto aquí o <em>haz clic para cargar</em></div>
                      <div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="Nombre">
                    <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                      <el-input v-model="form.name"></el-input>
                      <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Correo electrónico">
                    <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                      <el-input v-model="form.email"></el-input>
                      <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Contraseña">
                    <ValidationProvider name="Password" rules="required|confirmed:passwordc|min:6" v-slot="{ errors }">
                      <el-input v-model="form.password" show-password></el-input>
                      <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Confirmar contraseña">
                    <ValidationProvider name="Password Confirmation" rules="required" vid="passwordc" v-slot="{ errors }">
                      <el-input v-model="form.password_confirmation" show-password></el-input>
                      <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Permisos">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Marcar todos</el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="form.scopes" @change="handleCheckedCitiesChange">
                      <el-checkbox v-for="scope in scopes" :label="scope.value" :key="scope.value">{{scope.label}}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <el-form-item>
                  <el-button
                    @click.stop.prevent="onStore"
                    type="primary"
                    icon="el-icon-upload2" :disabled="invalid">
                    Agregar</el-button>
                  </el-form-item>
              </el-form>
            </ValidationObserver>
        </div>
      </el-card>
    </el-col>
  </el-row>
</div>
</template>
<script>

import { storeAdmin, getScopes } from '@/api/admin'
import { uploadImageFile } from '@/api/image'

export default {
  data () {
    return {
      form: {
        name: '',
        email: '',
        image: '',
        password: '',
        password_confirmation: '',
        scopes: []
      },
      loading: false,
      loadingDelete: false,
      isVisibleCollection: false,
      formEditCollection: {},
      imageUrl: '',
      checkAll: false,
      isIndeterminate: true,
      scopes: []
    }
  },
  created () {
    this.getAllScopes()
  },
  methods: {
    async onStore () {
      console.log(this.form)
      await storeAdmin(this.form)
        .then((response) => {
          this.$message({
            showClose: true,
            message: 'Done!',
            type: 'success'
          })
          // this.$router.push({ name: 'admin' })
        })
        .catch(this.responseCatch)
    },
    async getAllScopes () {
      await getScopes()
        .then((response) => {
          this.scopes = response.data
        })
    },
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('width', '250')
      formData.append('height', '250')
      formData.append('title', 'ProfileImage')
      formData.append('alt', 'WebERPImage')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data.path)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.form.image = res
    },
    beforeAvatarUpload (file) {
      var acceptFile = true
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 < 500

      if (!isJPG && !isPNG) {
        this.$message.error('La imagen debe estar en formato JPG o PNG!')
        acceptFile = false
      }
      if (!isLt2M) {
        this.$message.error('La imagen excede los 500kB!')
        acceptFile = false
      }
      return acceptFile
    },
    handleCheckAllChange (val) {
      var scopes = this.scopes.map((item) => {
        return item.value
      })
      this.form.scopes = val ? scopes : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      var checkedCount = value.length
      this.checkAll = checkedCount === this.scopes.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.scopes.length
    }
  }
}
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
</style>
